import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { ActuacioMatricula } from '../../models/actuacioMatricula.model';
import { ActuacioMatriculaFilter } from '../../models/actuacioMatricula-filter';
import { MAT_DIALOG_DATA, MatDialog, MatPaginator, MatSort } from '@angular/material';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { ActuacioMatriculaFilterService } from '../../services/ActuacioMatricula-filter.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActuacionsMapaComponentComponent } from '../actuacions-mapa-component/actuacions-mapa-component.component';

@Component({
  selector: 'app-actuacionsmatricula-table-component',
  templateUrl: './actuacionsmatricula-table-component.component.html',
  styleUrls: ['./actuacionsmatricula-table-component.component.scss']
})
export class ActuacionsmatriculaTableComponentComponent implements OnInit {
  dataSource: CustomDataSource<ActuacioMatricula, ActuacioMatriculaFilter>;
  displayedColumns: string[] = ['SOM_ID', 'matricula','Latitud','Longitud','Data','Tipus_Actuacio'];
  filter: ActuacioMatriculaFilter = new ActuacioMatriculaFilter('matricula', 'asc');
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  displayedItems: ActuacioMatricula[] = [];

  constructor(
    private filterService: FilterService,     
    @Inject(MAT_DIALOG_DATA) public dataOfDialog: ComponentDialog,
    private dialog: MatDialog,
    ) {
    if (dataOfDialog !== undefined && dataOfDialog.filter !==  undefined) {
      this.dataDialog = dataOfDialog;
    }
   }

  ngOnInit() {
    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.paginator.pageIndex = 0;
        this.filterService.saveFilter('matriculas-filter', this.filter);
      }
    });

    this.filter.filterCleared.subscribe(updated => {
      if (updated) {
        this.paginator.pageIndex = 0;
        this.filterService.saveFilter('matriculas-filter', this.filter);
      }
    });
    if (this.dataDialog !== undefined && this.dataDialog.filter !== null) {
      this.filter.set(this.dataDialog.filter);
      this.displayedColumns.splice(0, 1);
    } else {
       this.filterService.loadFilter('matriculas-filter', this.filter);
    }
    this.initDataSource();
  }

  
  private initDataSource() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<ActuacioMatricula, ActuacioMatriculaFilter>(this.paginator, new ActuacioMatriculaFilterService(), 'urlGetActuacionsMatriculas', 'urlGetActuacionsMatriculasCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach((item, index) => {
        this.displayedItems[index] = {
          MATRICULA: item.MATRICULA,
          SOM_ID: item.SOM_ID,
          LATITUD: item.LATITUD,
          LONGITUD: item.LONGITUD,
          DATA: item.DATA,
          TIPUS_ACTUACIO: item.TIPUS_ACTUACIO,
          ID_INSTIT: item.ID_INSTIT
        };
      });
    });
    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        this.filterService.saveFilter('actuacions-filter', this.filter);
      }
    });

    // this.filterService.loadFilter('actuacions-filter', this.filter);
    this.filter.paginator.init(this.sort, this.paginator);
  }

  
  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;

      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
      
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    this.paginator.page.subscribe(data => {
      this.paginator.pageIndex = data.pageIndex;
      this.paginator.pageSize = data.pageSize;
      console.log('paginator size: ' + data.pageSize);
    });
  }

  load() {
    if (this.dataSource !== undefined) 
    {
      this.filter.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.filter);
    }
  }

  onRowClicked(row): void {
    this.dialog.open(ActuacionsMapaComponentComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      data: {'somId' : row.SOM_ID, 'matricula' : row.MATRICULA, 'latitud' : row.LATITUD, 'longitud' : row.LONGITUD, 'fecha' : row.DATA, 'tipuActuacioIcon' : row.TIPUS_ACTUACIO.ICON_ROUTE},
      width: '90%',
      height: '90%',
    });
  }
}
