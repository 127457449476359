<app-toolbar>
    <app-tool-button text="Actualitzar"  i18n-text (click)="load();" color="has-color-blue" icon="fa-sync"></app-tool-button>
    <app-tool-button text="Importador" i18n-text (click)="this.goImportador()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
    <app-tool-button text="Filtre" i18n-text (click)="onFilterClicked();" color="has-color-orange" icon="fa-filter"></app-tool-button>
</app-toolbar>
<div class="row" class="text-center"><h4 i18n></h4></div>
  <div class="row">
    <div class="d-flex justify-content-between">
      <div class="flex-grow-1">&nbsp;</div>
      <div class="">
        <mat-paginator #paginatorDetail
          [pageIndex]="0"
          [pageSize]="this.filter.paginator.pageSize"
          [pageSizeOptions]="[20, 40, 80, 100]">
        </mat-paginator>
      </div>
    </div>
  </div>
<div class="table-sdw is-fixed">

    <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" class="is-header-sticky">

        <ng-container matColumnDef="nom">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Nom arxiu</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.nom}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="data">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.data }}</mat-cell>
        </ng-container>  

        <ng-container matColumnDef="estat">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Estat</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.estat}}</mat-cell>
        </ng-container>      
        <ng-container matColumnDef="error">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Error</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.error}}</mat-cell>
        </ng-container>          

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset"></mat-row>
    </mat-table>
</div>